<template>
    <div>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#13736f"
        spinner="spinner"
      />
      <v-layout class="mainfont" pb-10 wrap justify-center>
        <v-flex xs10 pt-4 pb-6>
          <v-card class="pr-10 pt-10 pl-10">
            <v-layout wrap pt-6 justify-center>
                <v-flex xs4 pt-4>
                <v-autocomplete
                  outlined
                  
                  color="#8d8d8d"
                  :items="category"
                  v-model="category"
                  item-text="name"
                  item-color="#FF1313"
                  hide-details
                  clearable
                  label="Category"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex xs4 pt-4 pl-3 pr-3>
                <v-autocomplete
                  outlined
                  
                  color="#8d8d8d"
                  :items="lottype"
                  v-model="lotType"
                  item-text="name"
                  item-color="#FF1313"
                  hide-details
                  clearable
                  label="Lot Type"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex  xs4 pt-4>
                <v-autocomplete
                  outlined
                  
                  color="#8d8d8d"
                  :items="harvest"
                  v-model="harvest"
                  item-text="name"
                  item-color="#FF1313"
                  hide-details
                  clearable
                  label="Harvest"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex xs4 pt-4>
                <v-layout wrap>
                  <v-flex xs5 pa-3 v-for="(item, i) in photos" :key="i">
                    <v-img :src="mediaURL + item"></v-img>
                  </v-flex>
  
                  <v-flex xs6>
                    <Images
                      @stepper="winStepper"
                      :height="'800'"
                      :width="'600'"
                      :heading="'Upload Images'"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
  
              <v-flex pt-4 xs4 pr-3 pl-3>
                <v-text-field
                  class="textField2"
                  
                  v-model="expectedPrice"
                  outlined
                  label="Expected  Price (Rs)"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex pt-4 xs4 >
                <v-text-field
                  class="textField2"
                  
                  v-model="startingPrice"
                  outlined
                  label="Starting  Price (Rs)"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      clearable
                      
                      outlined
                      label=" Date"
                      readonly
                      v-model="fromDate"
                      v-bind="attrs"
                      v-on="on"
                      :value="formattedDate"
                      @click:clear="from = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    color="#13736f"
                    @change="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs4  pl-3 pr-3>
                <template>
                  <v-row>
                    <v-col cols="12">
                      <v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="startingTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="startingTime"
                            outlined
                            
                            label="Starting Time"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu2"
                          v-model="startingTime"
                          full-width
                          @click:minute="$refs.menu.save(startingTime)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </template>
              </v-flex>
              <v-flex xs4>
                <template>
                  <v-row>
                    <v-col cols="12">
                      <v-menu
                        ref="menu2"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="menu2"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="endingTime"
                            label="Ending Time"
                            
                            outlined
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu3"
                          v-model="endingTime"
                          full-width
                          @change="$refs.menu2.save(endingTime)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </template>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  class="textField2"
                  
                  v-model="bagCount"
                  outlined
                  label="Bag Count"
                  required
                ></v-text-field>
              </v-flex>
  
              <v-flex xs4>
                <v-text-field
                  class="textField2"
                  
                  v-model="bagPrice"
                  outlined
                  label="Bag Price(Rs)"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs5 v-if="categoryId === '6464813503997785068a37b8'">
                <span>Grades</span>
                <v-card elevation="0" v-for="(item, i) in grades" :key="i">
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-layout wrap>
                        <v-flex xs12 pt-3>
                          <v-autocomplete
                            outlined
                            
                            color="#8d8d8d"
                            :items="itemArray"
                            v-model="item.grade"
                            item-text="name"
                            item-color="#FF1313"
                            hide-details
                            clearable
                            label="Change Grade"
                          >
                          </v-autocomplete>
                        </v-flex>
                        <v-flex xs12 pt-6>
                          <v-text-field
                            class="textField2"
                            
                            v-model="item.clean"
                            outlined
                            label="Clean (in %)"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            class="textField2"
                            
                            v-model="item.sickSplit"
                            outlined
                            label="Sick & Split (in %)"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            class="textField2"
                            
                            v-model="item.fruitWhitish"
                            outlined
                            label="Fruit & Whitish (in %)"
                            required
                          ></v-text-field
                        ></v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex
                xs5
                pt-3
                v-if="
                  categoryId === '6464813503997785068a37b8' ||
                  categoryId === '6482af8b27d90e40a40a2ff2'
                "
              >
                <v-text-field
                  class="textField2"
                  
                  v-model="literWeight"
                  outlined
                  label="Liter Weight (gm)"
                  required
                ></v-text-field>
              </v-flex>
  
              <v-flex xs5>
                <v-text-field
                  class="textField2"
                  dense
                  v-model="moistureContent"
                  outlined
                  label="Moisture Content (%)"
                  required
                ></v-text-field>
              </v-flex>
  
              <v-flex xs5 v-if="categoryId === '6464813503997785068a37b8'">
                <v-autocomplete
                  outlined
                  dense
                  color="#8d8d8d"
                  :items="color"
                  v-model="majorityColor"
                  item-text="name"
                  item-color="#FF1313"
                  hide-details
                  clearable
                  label="Majority Color"
                >
                </v-autocomplete>
              </v-flex>
  
              <v-flex xs5 pt-4>
                <v-text-field
                  class="textField2"
                  
                  v-model="netWeight"
                  outlined
                  label="Net Weight ( Kg) "
                  required
                ></v-text-field>
              </v-flex>
  
              <v-flex
                xs5
                v-if="
                  categoryId === '648d58b691a663942af701ae' ||
                  categoryId === '6482af8b27d90e40a40a2ff2'
                "
              >
                <v-text-field
                  class="textField2"
                  
                  v-model="dust"
                  outlined
                  label="Dust (%)"
                  required
                ></v-text-field>
              </v-flex>
  
              <v-flex xs5 v-if="categoryId === '648d58b691a663942af701ae'">
                <v-text-field
                  class="textField2"
                  dense
                  v-model="buds"
                  outlined
                  type="number"
                  label="Buds (%)"
                  required
                ></v-text-field>
              </v-flex>
  
              <v-flex xs5 v-if="categoryId === '6482af8b27d90e40a40a2ff2'">
                <v-text-field
                  class="textField2"
                  dense
                  v-model="white"
                  outlined
                  label="White Pepper (%)"
                  required
                ></v-text-field>
              </v-flex>
  
              <v-flex xs5 v-if="categoryId === '6482af8b27d90e40a40a2ff2'">
                <v-autocomplete
                  outlined
                  dense
                  color="#8d8d8d"
                  :items="itemArray2"
                  v-model="size"
                  item-text="name"
                  item-color="#FF1313"
                  hide-details
                  clearable
                  label=" Size"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex xs5 v-if="categoryId === '6482af8b27d90e40a40a2ff2'">
                <v-checkbox
                  outlined
                  dense
                  v-model="isFungus"
                  label=" Fungus Affected"
                ></v-checkbox>
              </v-flex>
  
              <v-flex xs5 text-right pb-10>
                <v-layout pl-5 wrap justify-end>
                  <v-flex xs4 v-if="status === 'Pending'" pa-3>
                    <v-btn
                      height="130%"
                      block
                      color="#13736f"
                      dark
                      @click="approve = true"
                    >
                      <span
                        class="mainfont"
                        style="color: white; font-size: 14px"
                      >
                        Approve
                      </span>
                      <v-icon size="110%" color="white">mdi-check-all</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex xs4 v-if="status === 'Pending'" pl-3 pb-3 pt-3>
                    <v-btn
                      height="130%"
                      block
                      color="#13736f"
                      dark
                      @click="reject = true"
                    >
                      <span
                        class="mainfont"
                        style="color: red; font-size: 14px; font-weight: bold"
                        >Reject
                      </span>
                      <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                  </v-flex>
                  <!-- <v-flex xs4 v-else pl-6>
                  <v-btn
                  width="160px" color="#13736f"
                    block
                    
                    dark
                    @click="edit()"
                  >
                    <span class="mainfont" style="color: white; font-size: 14px;"> Edit </span>
                    <v-icon size="110%" color="white">mdi-pencil</v-icon>
                  </v-btn>
                </v-flex> -->
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
     
    </div>
  </template>
        
  <script>
  import axios from "axios";
  import Images from "@/components/Common/multipleImages";
  import moment from "moment";
  export default {
    components: {
      Images,
    },
    data() {
      return {
        approve: false,
        remarks: null,
        preview: null,
        itemArray: ["8mm", "7mm", "6mm"],
        itemArray2: ["120", "80", "40"],
        color: ["Normal Green", "Reddish", "Light Yellow"],
        lottype: ["Bulk", "Average", "Few"],
        harvest: ["New", "Old"],
        isFungus: false,
        link: null,
        page: 1,
        reject: null,
        categoryId: null,
        startingTime: "",
        endingTime: "",
        category:[],
        time: null,
        menu2: false,
        menu3: false,
        modal2: false,
        currentpage: 1,
        showSnackBar: false,
        msg: null,
        limit: 10,
        url: null,
        categoryName: null,
        id: this.$route.query.id,
        imageFiles: [],
        appLoading: false,
        name: null,
        description: null,
        duration: null,
        pages: 0,
        menu1: false,
        icon: null,
        formData: new FormData(),
        formData2: new FormData(),
        listingOrder: null,
        list: [],
        curid: [],
        grades: [],
        cdate: {},
        startingPrice: null,
        deletedialog: false,
        time2: null,
        editdialog: false,
        noOfDays: null,
        amount: null,
        dialog2: false,
      };
    },
    mounted() {
      this.getList();
    },
    computed: {
      formattedDate() {
        const date = new Date(this.cdate);
        return date.toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      },
    },
    created() {
      this.currentPage2 = parseInt(this.$route.query.currentPage2) || 1;
    },
  
    methods: {
      edit() {
        this.formData2.append("lotType", this.lotType);
        this.formData2.append("harvest", this.list.harvest);
        this.formData2.append("expectedPrice", this.list.expectedPrice);
        this.formData2.append("startingPrice", this.list.startingPrice);
        this.formData2.append("date", this.cdate);
        const startingDateTime = moment(
          `${this.cdate} ${this.startingTime}`,
          "YYYY-MM-DD hh:mm A"
        ).format("YYYY-MM-DD HH:mm:ss");
        this.formData2.append("startingTime", startingDateTime);
        const endingDateTime = moment(
          `${this.cdate} ${this.endingTime}`,
          "YYYY-MM-DD hh:mm A"
        ).format("YYYY-MM-DD HH:mm:ss");
        this.formData2.append("endingTime", endingDateTime);
        this.formData2.append("bagCount", this.list.bagCount);
        this.formData2.append("bagPrice", this.list.bagPrice);
        this.formData2.append("literWeight", this.list.literWeight);
        this.formData2.append("moistureContent", this.list.moistureContent);
        this.formData2.append("majorityColor", this.list.majorityColor);
        this.formData2.append("netWeight", this.list.netWeight);
        this.formData2.append("dust", this.list.dust);
        this.formData2.append("white", this.list.white);
        this.formData2.append("size", this.list.size);
        this.formData2.append("isFungus", this.list.isFungus);
        this.formData2.append("id", this.list._id);
        const editedGrades = [];
        this.grades.forEach((item) => {
          const editedGrade = {
            grade: item.grade,
            clean: item.clean,
            fruitWhitish: item.fruitWhitish,
            sickSplit: item.sickSplit,
          };
          editedGrades.push(editedGrade);
        });
        this.formData2.append("grades", JSON.stringify(editedGrades));
        for (var i = 0; i < this.imageFiles.length; i++) {
          this.formData2.append(
            "photos",
            this.imageFiles[i],
            this.imageFiles[i].name
          );
        }
        axios({
          method: "post",
          url: "/admin/lot/edit",
          data: this.formData2,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              this.editdailog = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
              location.reload();
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
  
   
  
   
      winStepper(window_data) {
        if (window_data.type == "AddPackage") {
          this.slotAddition = window_data.slotAddition;
          this.packageRate = window_data.response;
        } else if (window_data.type == "image") {
          this.imageArray = window_data.imageArray;
          this.imageFiles.push(window_data.selectedFiles);
        } else if (window_data.type == "imageRemoval") {
          var values = this.formData.getAll("photos");
          values.splice(window_data.removalItem, 1);
          this.formData.set("photos", values);
          this.imageArray = window_data.imageArray;
        } else if (window_data.type == "topimage") {
          this.topimageFile = window_data.selectedFiles;
        } else if (window_data.type == "product top image") {
          this.producttopimagefile = window_data.selectedFiles;
        } else if (window_data.type == "product right image") {
          this.productrightimagefile = window_data.selectedFiles;
        }
      },
      getList() {
        axios({
          method: "GET",
          url: "category/list/",
          headers: {
            token: localStorage.getItem("token"),
          },
         
        })
          .then((response) => {
            this.category = response.data.data;
          
          
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
      
      
